<template>
  <ion-page class="ion-page">
    <GoBackHeader :default-href="`/facilities/${id}`" />

    <ion-content>
      <Refresher @refresh="doRefresh($event, 'getPassedFacilityEvents')" />
      <List
        :header="$t('allpassedevents')"
        :not-found-message="$t('nopassedeventslabel')"
        :loading="$apollo.loading && !getPassedFacilityEvents"
        :loading-more="loadingMore"
        @loadMore="loadMore($event, 'getPassedFacilityEvents')"
      >
        <template v-slot:top>
            <PassedEventSelectionFilter @change="changeFilter" default-filter-by="ALL" />
        </template>

        <template v-if="getPassedFacilityEvents && getPassedFacilityEvents.data && getPassedFacilityEvents.data.length">
          <div v-for="event in getPassedFacilityEvents.data" :key="event.id" @click="openSingleEvent(event.id)">
            <EventItem :key="event.id" :event="event" button />
          </div>
        </template>
      </List>
    </ion-content>
  </ion-page>
</template>

<script>
import GoBackHeader from '@/components/navigation/GoBackHeader';
import List from '@/components/List';
import { infiniteScrollMixin } from '@/mixins';
import { defaultPaginationConfig } from '@/configs';
import EventItem from '@/components/items/EventItem';
import { GET_PASSED_FACILITY_EVENTS } from '../../graphql/queries';
import PassedEventSelectionFilter from "@/components/items/PassedEventSelectionFilter";

export default {
  components: {
    GoBackHeader,
    List,
    EventItem,
    PassedEventSelectionFilter,
  },
  mixins: [infiniteScrollMixin],
  props: [
    'id',
  ],
  data() {
    return {
      filterBy: 'ALL',
    }
  },
  methods: {
    openSingleEvent(id) {
      this.$router.push({ name: this.$routeNames.EVENT, params: { id }})
    },
    changeFilter(filterOption) {
      this.filterBy = filterOption.key
      this.resetInfiniteScroll();
    },
    async doRefresh(event, model) {
      this.resetInfiniteScroll();
      await this.$apollo.queries[model].refetch();
      event.target.complete()
    }
  },
  apollo: {
    getPassedFacilityEvents: {
      query: GET_PASSED_FACILITY_EVENTS,
      variables() {
        return {
          ...defaultPaginationConfig,
          facility_id: this.id,
          filter_by: this.filterBy,
        }
      },
    },

  }
}
</script>
